import FileSaver from "file-saver";
import { Link } from "react-router-dom";
import XLSX from "xlsx/dist/xlsx.full.min";
import { json, useNavigate } from "react-router";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useState, useEffect } from "react";
import { Container, Button, Row, Col, Spinner } from "reactstrap";
import paginationFactory from "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import Swal from "sweetalert2";
import axios from "axios";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
import { SelectYear } from "../component/SelectYear";

function DaftarOnProcess() {
  const [isLoaded, setIsLoaded] = useState(true);
  const [validationError, setValidationError] = useState({});
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const [hapus, setHapus] = useState([]);
  const [numberedData, setNumberedData] = useState([]);

  const [selectedOption, setSelectedOption] = useState("");
  const [isOptionSelected, setIsOptionSelected] = useState(false);

  const changeTextColor = () => {
    setIsOptionSelected(true);
  };

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    if (!token && role !== "admin") {
      navigate("/login");
      return;
    }

    setIsAuthenticated(true);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    fetch(`https://subdomain.sudbalam.com/api/onprocess`, {
      headers: {
        Authorization: `Bearer ${token}`,
        
      },
    })
      .then((response) => response.json())
      .then((json) => setData(json));
  }, []);

  const handleExport = () => {
    const dataArray = data.map((d) => [
      d.nama_alm,
      d.nik_alm,
      d.no_akte,
      d.alamat_alm,
      d.kelurahan_alm,
      d.kecamatan_alm,
      d.tgl_alm,
      d.jam_alm,
      d.nama_waris,
      d.nik_waris,
      d.tlpn_waris,
    ]);

    const dataWithHeader = [
      [
        "Nama Almarhum",
        "NIK Almarhum",
        "Nomor Akte Kematian",
        "Alamat",
        "Kelurahan",
        "Kecamatan",
        "Tanggal Kematian",
        "Jam Kematian",
        "Nama Penerima (ahli waris)",
        "NIK Penerima (ahli waris)",
        "Nomor Telepon",
      ],
    ].concat(dataArray);
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(dataWithHeader);
    XLSX.utils.book_append_sheet(wb, ws, "Data");

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    FileSaver.saveAs(new Blob([wbout]), "data.xlsx");
  };

  useEffect(() => {
    setIsLoaded(true);
    const token = localStorage.getItem("token");
    console.log(token);
    
    fetch(`https://subdomain.sudbalam.com/api/onprocess`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setItems(result);
          setIsLoaded(false);
        },
        (error) => {
          setIsLoaded(false);
          setError(error);
        }
      );
  }, [selectedOption]);

  console.log(items);
  

  const defaultSorted = [
    {
      dataField: "no_bkp",
      order: "asc",
    },
  ];

  const getNumber = (index) => {
    return index + 1;
  };


  useEffect(() => {
    const updatedNumberedData = items.map((item, index) => {
      return { ...item, no: getNumber(index) };
    });
    setNumberedData(updatedNumberedData);
  }, [items, selectedOption]);

  const columns = [
    {
      dataField: "no",
      text: "NO",
      sort: true,
      style: {
        fontSize: "10px",
        textAlign: "center",
      },
      headerStyle: {
        backgroundColor: "grey",
        color: "#000000",
        textAlign: "center",
        verticalAlign: "middle",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        cursor: "pointer",
        fontSize: "10px",
        fontWeight: "bold",
      },
    },
    {
      dataField: "nik_alm",
      text: "NIK Almarhum",
      sort: true,
      style: {
        fontSize: "10px",
        textAlign: "center",
      },
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "grey",
        color: "#000000",
        textAlign: "center",
        verticalAlign: "middle",
        fontWeight: "bold",
        fontSize: "10px",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        cursor: "pointer",
      },
    },
    {
      dataField: "nama_alm",
      text: "Nama Almarhum",
      sort: true,
      style: {
        fontSize: "10px",
        textAlign: "center",
      },
      headerStyle: {
        backgroundColor: "grey",
        color: "#000000",
        textAlign: "center",
        verticalAlign: "middle",
        fontWeight: "bold",
        fontSize: "10px",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        cursor: "pointer",
      },
    },
    {
      dataField: "nik_waris",
      text: "NIK Penerima (Ahli Waris)",
      sort: true,
      style: {
        fontSize: "10px",
      },
      headerStyle: {
        backgroundColor: "grey",
        color: "#000000",
        textAlign: "center",
        verticalAlign: "middle",
        fontWeight: "bold",
        fontSize: "10px",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        cursor: "pointer",
      },
    },
    {
      dataField: "nama_waris",
      text: "Nama Penerima (Ahli Waris)",
      sort: true,
      style: {
        fontSize: "10px",
        textAlign: "center",
      },
      headerStyle: {
        backgroundColor: "grey",
        color: "#000000",
        textAlign: "center",
        verticalAlign: "middle",
        fontWeight: "bold",
        fontSize: "10px",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        cursor: "pointer",
      },
    },
    {
      dataField: "no_akte",
      text: "No Akte Kematian",
      sort: true,
      style: {
        fontSize: "10px",
      },
      headerStyle: {
        backgroundColor: "grey",
        color: "#000000",
        textAlign: "center",
        verticalAlign: "middle",
        fontWeight: "bold",
        fontSize: "10px",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        cursor: "pointer",
      },
    },
    {
      dataField: "alamat_alm",
      text: "Alamat",
      sort: true,
      style: {
        fontSize: "10px",
      },
      headerStyle: {
        backgroundColor: "grey",
        color: "#000000",
        textAlign: "center",
        verticalAlign: "middle",
        fontWeight: "bold",
        fontSize: "10px",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        cursor: "pointer",
      },
    },
    {
      dataField: "kelurahan_alm",
      text: "Kelurahan",
      sort: true,
      style: {
        fontSize: "10px",
      },
      headerStyle: {
        backgroundColor: "grey",
        color: "#000000",
        textAlign: "center",
        verticalAlign: "middle",
        fontWeight: "bold",
        fontSize: "10px",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        cursor: "pointer",
      },
    },
    {
      dataField: "kecamatan_alm",
      text: "Kecamatan",
      sort: true,
      style: {
        fontSize: "10px",
      },
      headerStyle: {
        backgroundColor: "grey",
        color: "#000000",
        textAlign: "center",
        verticalAlign: "middle",
        fontWeight: "bold",
        fontSize: "10px",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        cursor: "pointer",
      },
    },
    {
      dataField: "tgl_alm",
      text: "Tanggal Meninggal",
      sort: true,
      style: {
        fontSize: "10px",
      },
      headerStyle: {
        backgroundColor: "grey",
        color: "#000000",
        textAlign: "center",
        verticalAlign: "middle",
        fontWeight: "bold",
        fontSize: "10px",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        cursor: "pointer",
      },
    },
    {
      dataField: "jam_alm",
      text: "Jam Meninggal",
      sort: true,
      style: {
        fontSize: "10px",
      },
      headerStyle: {
        backgroundColor: "grey",
        color: "#000000",
        textAlign: "center",
        verticalAlign: "middle",
        fontWeight: "bold",
        fontSize: "10px",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        cursor: "pointer",
      },
    },

    {
      dataField: "tlpn_waris",
      text: "Telepon",
      sort: true,
      style: {
        fontSize: "10px",
      },
      headerStyle: {
        backgroundColor: "grey",
        color: "#000000",
        textAlign: "center",
        verticalAlign: "middle",
        fontWeight: "bold",
        fontSize: "10px",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        cursor: "pointer",
      },
    },
  ];

  const options = {
    paginationSize: 3,
    pageStartIndex: 1,
    sizePerPageList: [
      { text: "10", value: 10, className: "my-custom-page-size" },
      { text: "25", value: 25, className: "my-custom-page-size" },
      { text: "50", value: 50, className: "my-custom-page-size" },
      { text: "100", value: 100, className: "my-custom-page-size" },
    ],
  };

  if (error) {
    return <div>ErrorL {error.message}</div>;
  } else if (isLoaded) {
    return <div className="loading"></div>;
  } else {
    return (
      <main className="body">
        <div className="datatableTitle">
          Data Pengambilan Dana Santunan Kematian Dalam Proses
        </div>
        <ToolkitProvider
          keyField="id"
          data={numberedData}
          columns={columns}
          bootstrap4
          search
          defaultSorted={defaultSorted}
        >
          {(props) => (
            <div>
              <Row>
                <Col>
                  <div className="filter">
                    <div className="search">
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search .."
                        searchPlaceholder=""
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory(options)}
                wrapperClasses="table-responsive"
                filter={filterFactory()}
              />
            </div>
          )}
        </ToolkitProvider>
        <div className="excel">
          <button onClick={handleExport} className="export-excel">
            <span>Export to Excel</span>
          </button>
        </div>
      </main>
    );
  }
}

export default DaftarOnProcess;
